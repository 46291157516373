import React from 'react'

import { trackSegmentEvent } from 'lib/@getethos/analytics/trackSegmentEvent'

type AnalyticsEvent = {
  name: string
  properties: {
    module?: string
    ctaLabel?: string
    userData?: string
    [key: string]: any
  }
}

type UrlProps = {
  url?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}
/**
 * Handles global click events by preventing default behavior, tracking analytics events,
 * calling a callback function (if provided), and opening a URL in a new window (if provided).
 *
 * @param {Object} props - The props object.
 * @param {MouseEvent | React.MouseEvent} props.event - The click event.
 * @param {AnalyticsEvent} props.analyticsEvent - The analytics event to track.
 * @param {Function} [props.callback] - The optional callback function to call.
 * @param {UrlProps} [props.urlProps] - The optional URL properties object.
 */

function globalClickHandler(props: {
  event: MouseEvent | React.MouseEvent | React.KeyboardEvent
  analyticsEvent: AnalyticsEvent
  callback?: () => void
  urlProps?: UrlProps
}) {
  const { event, analyticsEvent, callback, urlProps } = props

  // Prevent default click behavior
  event.preventDefault()

  const isAnalyticsInitialized = window?.analytics?.initialized

  const trackSegmentPromise = new Promise<void>((resolve) => {
    if (isAnalyticsInitialized) {
      trackSegmentEvent({
        event: analyticsEvent.name,
        properties: analyticsEvent.properties,
        callback: () => {
          resolve()
        },
      })
    } else {
      resolve() // Resolve even if analytics is not initialized
    }
  })

  // Promise to execute the optional callback
  const callbackPromise = new Promise<void>((resolve) => {
    callback?.()
    resolve()
  })

  // Wait for both promises to resolve
  Promise.all([trackSegmentPromise, callbackPromise]).then(() => {
    if (urlProps?.url) {
      // Open the provided URL in a new window/tab
      window.open(urlProps.url, urlProps.target || '_self')
    }
  })
}

export { globalClickHandler }
